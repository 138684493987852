import { useEffect } from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReservationSteps from '../../components/ReservationSteps';
import { useAddNewReservationMutation } from './reservationsApiSlice';
import useAuth from '../../hooks/useAuth';

function ReservationSummary() {
    const [addNewReservation, { isLoading, isSuccess, isError, error }] = useAddNewReservationMutation();
    const { email } = useAuth();
    const { cardItems, totalPrice, reservationLocation, reservationDate, reservationClientInfo } = useSelector((state) => state.card);
    const navigate = useNavigate();

    // Redirect to relevant pages if any step is incomplete
    useEffect(() => {
        if (cardItems.length === 0) navigate('/reservation-services');
        else if (!reservationLocation.locationReserved) navigate('/reservation-location');
        else if (!reservationDate.dateReserved) navigate('/reservation-date');
        else if (!reservationClientInfo.phone) navigate('/reservation-client-info');
    }, [navigate, cardItems.length, reservationLocation.locationReserved, reservationDate.dateReserved, reservationClientInfo.phone]);

    // Check if reservation is fully fulfilled
    const isReservationFulfilled = cardItems.length > 0 && reservationLocation.locationReserved && reservationDate.dateReserved && reservationClientInfo.phone;

    const reservationSummaryHandler = async (e) => {
        e.preventDefault();
        await addNewReservation({
            email,
            reservationServices: cardItems,
            reservationLocation,
            reservationDate,
            reservationClientInfo,
            totalPrice,
        });
        localStorage.removeItem('card');
    };

    useEffect(() => {
        if (isSuccess) {
            localStorage.removeItem('card');
            navigate('/dashboard/reservation-confirmation');
        }
    }, [isSuccess, navigate]);

    return (
        <div className="container py-5">
            {/* Loading State */}
            {isLoading && (
                <div className="text-center">
                    <h2>En cours de traitement... Veuillez patienter.</h2>
                </div>
            )}

            {/* Error State */}
            {isError && (
                <div className="text-center text-danger">
                    <h2>Une erreur est survenue : {error?.message || 'Veuillez réessayer plus tard.'}</h2>
                </div>
            )}

            {/* Success State */}
            {/* {isSuccess && (
                <div className="text-center text-success">
                <p>Votre réservation a été réussie! Vous recevrez un e-mail de confirmation sous peu.</p>
                </div>
            )} */}

            {/* Reservation Summary */}
            {!isLoading && !isError && !isSuccess && (
                <>
                    <ReservationSteps step1 step2 step3 step4 step5 step6 />
                    <div className="text-center py-3">
                        <h1>Réservation - Sommaire</h1>
                        <div className="col-lg-6 mx-auto">
                        <p className="lead mb-4">
                            Nous vous demandons de vérifier les informations ci-dessus. Si vous avez des modifications à apporter, veuillez les corriger.
                        </p>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div>
                            <ul className="list-group h6">
                            {cardItems.length === 0 && <h4>Votre panier est vide | Your cart is empty!</h4>}
                            {cardItems.map((item, index) => (
                                <li key={index} className="list-group-item d-flex justify-content-between lh-sm py-3">
                                <div>
                                    <i className="bi bi-calendar2-check"></i> {' '}
                                    Service: {item.titre} ({item.qty} {item.qty > 1 ? 'personnes' : 'personne'} x ${Number(item.price).toFixed(2)})
                                </div>
                                <span>
                                    <NavLink to="/reservation-services">
                                    <i className="bi bi-pencil-square"></i>
                                    </NavLink>
                                </span>
                                </li>
                            ))}
                            </ul>
                        </div>
                        <div className="pt-3">
                            <ul className="list-group h6">
                            <li className="list-group-item d-flex justify-content-between lh-sm py-3">
                                <div>
                                <i className="bi bi-geo-alt"></i> {' '}
                                Endroit: {reservationLocation?.locationReserved === '1' ? 'La Pyramide (2360 chemin St-Foy, Québec, G1V 4H2)' : 'Plaza Laval (2750 chemin St-Foy, Québec, G1V 1V6)'}
                                </div>
                                <span>
                                <NavLink to="/reservation-location">
                                    <i className="bi bi-pencil-square"></i>
                                </NavLink>
                                </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm py-3">
                                <div>
                                <i className="bi bi-clock-history"></i> {' '}
                                Date: le {reservationDate?.dateReserved} à {reservationDate?.timeReserved}
                                </div>
                                <span>
                                <NavLink to="/reservation-date">
                                    <i className="bi bi-pencil-square"></i>
                                </NavLink>
                                </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm py-3">
                                <div>
                                <i className="bi bi-person-lines-fill"></i> {' '}
                                Client Info: {reservationClientInfo?.client} (Tel: {reservationClientInfo?.phone})
                                </div>
                                <span>
                                <NavLink to="/reservation-client-info">
                                    <i className="bi bi-pencil-square"></i>
                                </NavLink>
                                </span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-sm py-3">
                                <div>
                                <i className="bi bi-chat-square-text"></i> {' '}
                                Commentaire: {reservationClientInfo?.comment}
                                </div>
                                <span>
                                <NavLink to="/reservation-client-info">
                                    <i className="bi bi-pencil-square"></i>
                                </NavLink>
                                </span>
                            </li>
                            </ul>
                        </div>

                        <button
                            type="button"
                            className="btn btn-info btn-lg text-uppercase my-5"
                            disabled={!isReservationFulfilled || isLoading}
                            onClick={reservationSummaryHandler}
                        >
                            Passer la commande
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}

export default ReservationSummary;