import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const PaginationReservationList = ({ pages }) => {
    const { pageNumber = 1, keyword = '' } = useParams(); // Get page number and keyword from URL
    const [page, setPage] = useState(parseInt(pageNumber, 10) || 1); // Set initial page based on URL
    const navigate = useNavigate();

    // Update page state whenever the pageNumber changes in the URL
    useEffect(() => {
        setPage(parseInt(pageNumber, 10) || 1);
    }, [pageNumber]);

    // Handle page change logic
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= pages) {
            // If there's a keyword, include it in the URL; otherwise, omit it
            if (keyword) {
                navigate(`/dashboard/reservations/search/${keyword}/page/${newPage}`);
            } else {
                navigate(`/dashboard/reservations/page/${newPage}`);
            }
        }
    };

    return (
        pages > 1 && (
            <nav aria-label="Reservations page pagination">
                <ul className="pagination">
                    {/* Previous Button */}
                    <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
                        <button 
                            className="page-link"
                            onClick={() => handlePageChange(page - 1)}
                            disabled={page === 1}
                        >
                            <i className="fa-solid fa-caret-left"></i>
                        </button>
                    </li>

                    {/* Page Numbers */}
                    {Array.from({ length: pages }, (_, index) => (
                        <li 
                            className={`page-item ${page === index + 1 ? "active" : ""}`} 
                            key={`page-${index + 1}`}
                        >
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        </li>
                    ))}

                    {/* Next Button */}
                    <li className={`page-item ${page === pages ? "disabled" : ""}`}>
                        <button
                            className="page-link"
                            onClick={() => handlePageChange(page + 1)}
                            disabled={page === pages}
                        >
                            <i className="fa-solid fa-caret-right"></i>
                        </button>
                    </li>
                </ul>
            </nav>
        )
    );
};

export default PaginationReservationList;