import { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useGetPublicServicesQuery } from "../services/servicesApiSlice";
import { useCreateReservationMutation } from "./reservationsApiSlice";
import ServicePublicSelect from "../services/ServicePublicSelect";
import { PHONE_REGEX } from "../../utils/regexValidation";
import LoadingSpinner from '../../components/LoadingSpinner';
import useAuth from '../../hooks/useAuth';
const NAME_REGEX = /^[a-zA-ZÀ-ÿ\- ']{3,}$/;

function NewReservation() {

    const {
        data: services,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetPublicServicesQuery('publicServices', {
        pollingInterval: 24 * 60 * 60 * 1000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    const [createReservation, {
        isLoading: isCreateReservationLoading,
        isSuccess: isCreateReservationSuccess,
        isError: isCreateReservationError,
        error: errorCreateReservation
    }] = useCreateReservationMutation();

    const { email } = useAuth();

    const navigate = useNavigate();

    const serviceRef = useRef(null);

    const referenceDate = new Date();
    const currentDate = referenceDate.toLocaleDateString('fr-CA');

    const hourOptions = Array.from({ length: 11 }, (_, i) => (9 + i).toString());

    const minuteOptions = Array.from({ length: 12 }, (_, i) => (i * 5).toString().padStart(2, '0'));

    const [client, setClient] = useState('')
    const [validClient, setValidClient] = useState(false);
    const [clientFocus, setClientFocus] = useState(false);

    const [phone, setPhone] = useState('')
    const [validPhone, setValidPhone] = useState(false);
    const [phoneFocus, setPhoneFocus] = useState(false);

    const setFormattedPhone = (newNumber) => {
        const digits = newNumber.replace(/\D/g, '');
        let formatted = digits.substring(0, 3);
        if (digits.length === 3 && newNumber[3] === '-') {
            formatted = `${formatted}-`;
        } else if (digits.length > 3) {
            formatted = `${formatted}-${digits.substring(3, 6)}`;
        }
        if (digits.length === 6 && newNumber[7] === '-') {
            formatted = `${formatted}-`;
        } else if (digits.length > 6) {
            formatted = `${formatted}-${digits.substring(6, 10)}`;
        }

        if (digits.length === 10) {
            serviceRef.current.focus();
        }
        setPhone(formatted);
    };

    const manicure = "manicure";
    const pedicure = "pedicure";
    const other = "other";

    const [serviceIdManicure, setServiceIdManicure] = useState('');
    const [qtyManicure, setQtyManicure] = useState(1);
    const [serviceIdPedicure, setServiceIdPedicure] = useState('');
    const [qtyPedicure, setQtyPedicure] = useState(1);
    const [serviceIdOther, setServiceIdOther] = useState('');
    const [qtyOther, setQtyOther] = useState(1);

    const [locationReserved, setLocationReserved] = useState('')
    const [performer, setPerformer] = useState('')
    const [comment, setComment] = useState('')

    const [date, setDate] = useState(currentDate);
    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');

    useEffect(() => {
        setValidClient(NAME_REGEX.test(client));
        setValidPhone(PHONE_REGEX.test(phone));
    }, [client, phone]);

    useEffect(() => {
        if (isCreateReservationSuccess) {
            setClient('')
            // setDescription('')
            // setStatus('')
            // setUserId('')
            navigate('/dashboard/reservations/possible');
        }
    }, [isCreateReservationSuccess, navigate])

    let content

    if (isLoading) {
        content = <LoadingSpinner />
    }

    if (isError) {
        content = (
            <main className="container py-3">
                 <h1 className="errmsg">{error?.data?.message}</h1>
            </main>
        )
    }

    const reservationClientInfo = { client, phone: phone || "418-255-2318", performer, comment };

    const reservationServices = [
        serviceIdManicure && qtyManicure > 0 ? { serviceId: serviceIdManicure, qty: qtyManicure } : null,
        serviceIdPedicure && qtyPedicure > 0 ? { serviceId: serviceIdPedicure, qty: qtyPedicure } : null,
        serviceIdOther && qtyOther > 0 ? { serviceId: serviceIdOther, qty: qtyOther } : null,
    ].filter(service => service !== null);
    
    const reservationLocation = { locationReserved };
    const reservationDate = { dateReserved : date, timeReserved : `${hour}:${minute}` };

    const regexFulfilment = [validClient].every(Boolean) && !isLoading;

    const reservationSummaryHandler = async (e) => {
        e.preventDefault();
        await createReservation({
            email,
            reservationServices,
            reservationLocation,
            reservationDate,
            reservationClientInfo
        });
        // navigate('/dashboard/reservations/pending');
        // navigate('/dashboard/reservations'); 
    }

    if (isSuccess) {
        const { ids } = services;
        const selectManicure = ids?.length && ids.map((serviceId) => <ServicePublicSelect key={serviceId} serviceId={serviceId} manicure={manicure} />);
        const selectPedicure = ids?.length && ids.map((serviceId) => <ServicePublicSelect key={serviceId} serviceId={serviceId} pedicure={pedicure} />);
        const selectOther = ids?.length && ids.map((serviceId) => <ServicePublicSelect key={serviceId} serviceId={serviceId} other={other} />);

        content = (
            <main className='container py-3'>
                <section>
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => navigate(-1)}
                        >
                            <i className="bi bi-chevron-left"></i>
                            Retour | Back
                        </button>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <Link to='/dashboard/reservations/pending' type="button" className="btn btn-outline-secondary">
                                <i className="bi bi-calendar3 me-1"></i>
                                Réservations à faire
                            </Link>
                        </div>
                    </div>
                </section>
                <div className='fw-bold'>
                    <dl className="row">
                        <dt className="col-sm-4"></dt>
                        <dd className="col-sm-6">
                            <h1>Rendez-vous par Admin</h1>
                        </dd>
                    </dl>
                </div>
                <form onSubmit={reservationSummaryHandler}>
                    <div className="row text-end">
                        <dl className="row">
                            <dt className="col-sm-4">Nom du client</dt>
                            <dd className="col-sm-6">
                                <input
                                    className={`form-control ${!client ? '' : validClient ? 'is-valid' : 'is-invalid'}`}
                                    type='text'
                                    value={client}
                                    onChange={(e) => setClient(e.target.value)}
                                    autoComplete="off"
                                    placeholder='Nom et prénom'
                                    required
                                    aria-invalid={validClient ? "false" : "true"}
                                    aria-describedby="clientnote"
                                    onFocus={() => setClientFocus(true)}
                                    onBlur={() => setClientFocus(false)}
                                />
                                <div id="clientnote" className={clientFocus && !validClient ? "invalid-feedback" : "visually-hidden"}>
                                    Veuillez spécifier votre prénom et nom en characters.
                                </div>
                            </dd>
                        </dl>
                    </div>
                    <div className='row text-end'>
                        <dl className="row">
                            <dt className="col-sm-4">Téléphone</dt>
                            <dd className="col-sm-6">
                                <input
                                    className={`form-control ${!phone ? '' : validPhone ? 'is-valid' : 'is-invalid'}`}
                                    type='text'
                                    value={phone}
                                    onChange={(e) => setFormattedPhone(e.target.value)}
                                    autoComplete="off"
                                    placeholder='999-999-9999'
                                    aria-invalid={validPhone ? "false" : "true"}
                                    aria-describedby="phonenote"
                                    onFocus={() => setPhoneFocus(true)}
                                    onBlur={() => setPhoneFocus(false)}
                                />
                                <div id="phonenote" className={phoneFocus && !validPhone ? "invalid-feedback" : "visually-hidden"}>
                                    Veuillez spécifier un numéro de téléphone valide (e.g: XXX-XXX-XXXX).
                                </div>
                            </dd>
                        </dl>
                    </div>
                    <div className="row text-end">
                        <dl className="row">
                            <dt className="col-sm-4">Manicure</dt>
                            <dd className="col-sm-6">
                                <div className='row'>
                                    <div className='col-md-9'>
                                        <select
                                            className='form-select'
                                            onChange={(e) => setServiceIdManicure(e.target.value)}
                                            ref={serviceRef}
                                        >
                                            <option value="">Choose a service...</option>
                                            <hr />
                                            {selectManicure}
                                        </select>
                                    </div>
                                    <div className='col-md-3'>
                                        <select 
                                            className="form-select" 
                                            onChange={(e) => setQtyManicure(e.target.value)}
                                        >
                                            {[...Array(4).keys()].map((x) => (
                                                <option  key={x + 1} value={x + 1}>
                                                    {x + 1}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                    </div>
                    <div className="row text-end">
                        <dl className="row">
                            <dt className="col-sm-4">Pedicure</dt>
                            <dd className="col-sm-6">
                                <div className='row'>
                                    <div className='col-md-9'>
                                        <select
                                            className='form-select'
                                            onChange={(e) => setServiceIdPedicure(e.target.value)}
                                            // ref={serviceRef}
                                        >
                                            <option value="">Choose a service...</option>
                                            <hr />
                                            {selectPedicure}
                                        </select>
                                    </div>
                                    <div className='col-md-3'>
                                        <select 
                                            className="form-select" 
                                            onChange={(e) => setQtyPedicure(e.target.value)}
                                        >
                                            {[...Array(4).keys()].map((x) => (
                                                <option  key={x + 1} value={x + 1}>
                                                    {x + 1}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                    </div>
                    <div className="row text-end">
                        <dl className="row">
                            <dt className="col-sm-4">Autre Services</dt>
                            <dd className="col-sm-6">
                                <div className='row'>
                                    <div className='col-md-9'>
                                        <select
                                            className='form-select'
                                            onChange={(e) => setServiceIdOther(e.target.value)}
                                            // ref={serviceRef}
                                        >
                                            <option value="">Choose a service...</option>
                                            <hr />
                                            {selectOther}
                                        </select>
                                    </div>
                                    <div className='col-md-3'>
                                        <select 
                                            className="form-select" 
                                            onChange={(e) => setQtyOther(e.target.value)}
                                            required
                                        >
                                            {[...Array(4).keys()].map((x) => (
                                                <option  key={x + 1} value={x + 1}>
                                                    {x + 1}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                    </div>
                    <div className="row text-end">
                        <dl className="row">
                            <dt className="col-sm-4">Location</dt>
                            <dd className="col-sm-6">
                                <select
                                    className="form-select"
                                    value={locationReserved}
                                    onChange={(e) => setLocationReserved(e.target.value)}
                                    required
                                >
                                    <option value=''>Select one...</option>
                                    <hr />
                                    <option value="1">La Pyramide</option>
                                    <hr />
                                    <option value="2">Plaza Laval</option>
                                </select>
                            </dd>
                        </dl>
                    </div>
                    <div className="row text-end">
                        <dl className="row">
                            <dt className="col-sm-4">Date and time</dt>
                            <dd className="col-sm-6">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={date}
                                            onChange={(e) => setDate(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className='col-md-3'>
                                        <select
                                            className="form-select"
                                            onChange={(e) => setHour(e.target.value)}
                                            required
                                        >
                                            <option value="">hour</option>
                                            {
                                                hourOptions.map((hour, index) => {
                                                    return (
                                                        <option
                                                            key={index} 
                                                            value={hour}
                                                        >
                                                            {hour}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='col-md-3'>
                                        <select
                                            className="form-select"
                                            onChange={(e) => setMinute(e.target.value)}
                                            required
                                        >
                                            <option value="">min</option>
                                            {
                                                minuteOptions.map((minute, index) => {
                                                    return (
                                                        <option
                                                            key={index} 
                                                            value={minute}
                                                            required
                                                        >
                                                            {minute}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </dd>
                        </dl>
                    </div>
                    <div className="row text-end">
                        <dl className="row">
                            <dt className="col-sm-4">Soin donné par</dt>
                            <dd className="col-sm-6">
                                <input
                                    className='form-control'
                                    type='text'
                                    value={performer}
                                    onChange={(e) => setPerformer(e.target.value)}
                                    autoComplete="off"
                                    placeholder='Nom du technicien (option)'
                                />
                            </dd>
                        </dl>
                    </div>
                    <div className='row text-end'>
                        <dl className="row">
                            <dt className="col-sm-4">Commentaires</dt>
                            <dd className="col-sm-6">
                                <textarea
                                    className="form-control"
                                    placeholder='Ajoutez un souhait personnalisé ici (option)'
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                >
                                </textarea>
                            </dd>
                        </dl>
                    </div>
                    <div className='row'>
                        <dl className="row">
                            <dt className="col-sm-4"></dt>
                            <dd className="col-sm-6">
                                <button
                                    type='submit'
                                    className='btn btn-primary btn-lg w-100 text-uppercase'
                                    disabled={!regexFulfilment}
                                    // disabled
                                >
                                    Réserver
                                </button>
                            </dd>
                        </dl>
                    </div>
                </form>
            </main>
        )
    }

    return content
}

export default NewReservation