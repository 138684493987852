import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveReservationClientInfo } from "./cardSlice";
import ReservationSteps from '../../components/ReservationSteps';
import { PHONE_REGEX } from "../../utils/regexValidation";
// import usePersist from "../../hooks/usePersist";
import useAuth from '../../hooks/useAuth';

function ReservationClientInfo() {
  // const [persist] = usePersist();

  const commentRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { reservationClientInfo } = useSelector((state) => state.card);

  // State with default values fallback
  const [client, setClient] = useState(reservationClientInfo?.client || '');
  const [phone, setPhone] = useState(reservationClientInfo?.phone || '');
  const [validPhone, setValidPhone] = useState(false);
  const [comment, setComment] = useState(reservationClientInfo?.comment || '');

  const setFormattedPhone = (newNumber) => {
    const digits = newNumber.replace(/\D/g, ''); // Remove non-numeric characters
    let formatted = digits;

    if (digits.length > 3) formatted = `${digits.slice(0, 3)}-${digits.slice(3)}`;
    if (digits.length > 6) formatted = `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6, 10)}`;

    setPhone(formatted);

    // Automatically move focus to the comment field when the phone number is complete
    if (digits.length === 10) {
      commentRef.current.focus();
    }
  };

  useEffect(() => {
    setValidPhone(PHONE_REGEX.test(phone)); // Validate phone format
  }, [phone]);

  // const reservationClientInfoHandler = (e) => {
  //   e.preventDefault();
  //   dispatch(saveReservationClientInfo({ client, phone, comment }));

  //   const targetRoute = persist ? '/reservation-summary' : '/login?redirect=/reservation-summary';
  //   navigate(targetRoute);
  //   // navigate('/login?redirect=/reservation-summary');
  // };

  const { email } = useAuth();

  const reservationClientInfoHandler = (e) => {
    e.preventDefault();
    dispatch(saveReservationClientInfo({ client, phone, comment }));

    let targetRoute = '/login?redirect=/reservation-summary';

    if (email) {
      targetRoute = '/reservation-summary';
    }

    navigate(targetRoute);
  };

  return (
    <div className="container py-5">
      <ReservationSteps step1 step2 step3 step4 step5 />
      <div className="text-center py-3">
        <h1>Réserver / Client Information</h1>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">
            Pour mieux vous servir, nous vous prions de bien vouloir nous fournir les informations suivantes
          </p>
        </div>
      </div>
      <hr />
      <form onSubmit={reservationClientInfoHandler}>
        <div className="mb-3">
          <label className="form-label" htmlFor="client">Votre nom et prénom (*)</label>
          <input
            className="form-control form-control-lg"
            type="text"
            placeholder="Nom et prénom"
            id="client"
            value={client}
            required
            onChange={(e) => setClient(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="phone">Numéro de téléphone (*)</label>
          <input
            className={`form-control form-control-lg ${!phone ? '' : validPhone ? 'is-valid' : 'is-invalid'}`}
            type="text"
            id="phone"
            value={phone}
            onChange={(e) => setFormattedPhone(e.target.value)}
            autoComplete="off"
            placeholder="999-999-9999"
            required
            aria-invalid={validPhone ? "false" : "true"}
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Commentaires (e.g: votre choix d'employé)?</label>
          <textarea
            className="form-control form-control-lg"
            placeholder="Ajoutez un souhait personnalisé ici (option)"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            ref={commentRef}
          />
        </div>
        <button type="submit" className="btn btn-info btn-lg">
          Continuer
        </button>
      </form>
    </div>
  );
}

export default ReservationClientInfo;