import { useState } from 'react';
import { Link } from "react-router-dom";
import { useGetPresentReservationsQuery } from "./reservationsApiSlice";
import LoadingSpinner from "../../components/LoadingSpinner";
import { currentDate } from "../../utils/dateUtils";
import ReservationListPresentItem from './ReservationListPresentItem';
// import carousel from '../../images/carousel.jpg';

function ReservationListPresent() {

    const {
        data: reservations,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetPresentReservationsQuery('reservationList', {
        // pollingInterval: 60000,
        pollingInterval: 6000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    // const [updateReservationToDone, {
    //     isLoading: isUpdateLoading,
    //     isSuccess: isUpdateSuccess,
    //     isError: isUpdateError,
    //     error: updateError
    // }] = useUpdateReservationToDoneMutation()

    const [date, setDate] = useState(currentDate);
    const [locationReserved, setLocationReserved] = useState('')

    const [search, setSearch] = useState('')

    // const [done, setDone] = useState(false)

    let content

    if (isLoading) {
        content = <LoadingSpinner />
    }

    if (isError) {
        content = (
            <main className="container py-3">
                 <h1 className="errmsg">{error?.data?.message}</h1>
            </main>
        )
    }
    
    if (isSuccess) {
        content = (
            <main className='container py-3'>
                {/* <section>
                    <div id="carouselExampleDark" className="carousel carousel-dark slide">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner">
                            {
                                reservations.map((reservation, index) => (
                                    <div className="carousel-item active" data-bs-interval="10000" key={index}>
                                        <img src={carousel} className="d-block w-100" alt="..." />
                                        <div className="carousel-caption d-none d-md-block">
                                            <h3>{reservation.reservationClientInfo.client}</h3>
                                            <h5>Some representative placeholder content for the first slide.</h5>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </section> */}
                <section>
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                        <h2>Reservations</h2>
                        <div className="d-flex align-items-center w-25">
                            <input
                                type="text"
                                className="form-control rounded-pill"
                                placeholder="Search..."
                                onChange={e => setSearch(e.target.value)}
                            />
                            <span style={{ position: "relative", left: "-27px" }}>
                                <i className="bi bi-search"></i>
                            </span>
                        </div>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className='me-2'>
                                <input
                                    type="date"
                                    className="form-control"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </div>
                            <div className="me-2">
                                <select
                                    className="form-select"
                                    value={locationReserved}
                                    onChange={(e) => setLocationReserved(e.target.value)}
                                >
                                    <option value="">All locations</option>
                                    <option value="1" >La Pyramide</option>
                                    <option value="2">Plaza Laval</option>
                                </select>
                            </div>
                            <div>
                                <Link to='/dashboard/reservations/new' className="btn btn-outline-secondary">
                                    Add
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    { isLoading ? <LoadingSpinner /> : isError ? {error} : (
                        <table className="table striped bordered hover responsive">
                            <thead>
                                <tr>
                                    <th scope="col">Order</th>
                                    <th scope="col">Time</th>
                                    <th scope="col">Client</th>
                                    <th scope="col">Service</th>
                                    <th scope="col">Technicien</th>
                                    <th scope="col">Comment</th>
                                    <th scope="col">Details</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    reservations.filter(reservation => (
                                        reservation.reservationDate.dateReserved === date &&
                                        ((locationReserved === '1' || locationReserved === "2") ? reservation.reservationLocation.locationReserved === locationReserved : reservation.reservationLocation.locationReserved !== locationReserved) && (reservation.reservationClientInfo.client.toLowerCase().includes(search.toLowerCase()) || reservation.reservationClientInfo.phone.includes(search) || reservation.reservationDate?.timeReserved.includes(search))
                                    )).map((reservation, index) => (
                                        <ReservationListPresentItem reservation={reservation} key={index} index={index} />
                                    ))
                                }
                            </tbody>
                        </table>
                    )}
                </section>
            </main>
        )
    }

    return content
}

export default ReservationListPresent;