import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetReservationsQuery } from "./reservationsApiSlice";
import LoadingSpinner from "../../components/LoadingSpinner";
import ReservationListItem from "./ReservationListItem";
import PaginationReservationList from "../../components/paginations/PaginationReservationList";
import SearchReservationList from "../../components/searchbox/SearchReservationList";
import BackButton from "../../components/buttons/BackButton";

function ReservationList() {
    const { pageNumber = 1, keyword = "" } = useParams();
    const navigate = useNavigate();

    const [page, setPage] = useState(parseInt(pageNumber));
    const [currentKeyword, setCurrentKeyword] = useState(keyword);
    const [reservationStatusOption, setReservationStatusOption] = useState('');
    const [reservationLocationOption, setReservationLocationOption] = useState('');

    const {
        data: reservationsData,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetReservationsQuery({
        keyword: currentKeyword,
        pageNumber: page,
        reservationStatusOption,
        reservationLocationOption,
    });

    useEffect(() => {
        // Sync state with URL params when pageNumber or keyword changes
        setPage(parseInt(pageNumber));
        setCurrentKeyword(keyword);
    }, [pageNumber, keyword]);

    const handleReservationStatusOption = (e) => {
        setReservationStatusOption(e.target.value);
        setPage(1); // Reset page to 1 when filter changes
    };

    const handleReservationLocationOption = (e) => {
        setReservationLocationOption(e.target.value);
        setPage(1); // Reset page to 1 when filter changes
    };

    const handleSearchSubmit = (newKeyword) => {
        setCurrentKeyword(newKeyword);
        setPage(1); // Reset page to 1 when search keyword changes
        navigate(`/dashboard/reservations/search/${newKeyword}`);
    };

    let content;

    if (isLoading) {
        content = <LoadingSpinner />;
    }

    if (isError) {
        content = (
            <main className="container text-center py-5">
                <h1 className="errmsg">{error?.data?.message || "An error occurred."}</h1>
                <div className="pt-3">
                    <BackButton />
                </div>
            </main>
        );
    }

    if (isSuccess) {
        const { reservations, pages, page } = reservationsData;

        const tableContent = reservations?.map((reservation, index) => (
            <ReservationListItem
                key={index}
                reservation={reservation}
                pageNumber={pageNumber}
                keyword={currentKeyword}
            />
        ));

        content = (
            <main className="container py-3">
                <section>
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                        <h2>Reservations</h2>
                        <SearchReservationList keyword={currentKeyword} onSearchSubmit={handleSearchSubmit} />
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="me-2">
                                <select 
                                    className="form-select"
                                    value={reservationStatusOption}
                                    onChange={handleReservationStatusOption}
                                >
                                    <option value="">All reservations</option>
                                    <option value="present">Reservations d'attend</option>
                                    <option value="pending">Reservations à faire</option>
                                    <option value="done">Reservations fait</option>
                                    <option value="cancel">Reservations annullé</option>
                                </select>
                            </div>
                            <div className="me-2">
                                <select 
                                    className="form-select"
                                    value={reservationLocationOption}
                                    onChange={handleReservationLocationOption}
                                >
                                    <option value="">All locations</option>
                                    <option value="1">La Pyramide</option>
                                    <option value="2">Plaza Laval</option>
                                </select>
                            </div>
                            <div>
                                <Link to="/dashboard/reservations/new" className="btn btn-outline-secondary">
                                    Add
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <table className="table striped bordered hover responsive">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Client</th>
                                <th>Service</th>
                                <th>Performer</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>{tableContent}</tbody>
                    </table>
                </section>
                <section className="pt-3">
                    <PaginationReservationList pages={pages} page={page} keyword={currentKeyword} />
                </section>
            </main>
        );
    }

    return content;
}

export default ReservationList;