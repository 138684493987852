import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <button className="btn btn-lg btn-primary" onClick={handleBackClick}>
            <i className="bi bi-arrow-left"></i> Retour | Back
        </button>
    );
};

export default BackButton;