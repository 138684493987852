import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const reservationsAdapter = createEntityAdapter({
    // sortComparer: (a, b) => (a.status === b.status) ? 0 : a.status ? 1 : -1
    // sortComparer: (a, b) => (a.reservationDate.timeReserved === b.reservationDate.timeReserved) ? 0 : a.reservationDate.timeReserved ? 1 : -1
})

const initialState = reservationsAdapter.getInitialState()

export const reservationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getReservations: builder.query({
            query: ({ keyword, pageNumber, reservationStatusOption, reservationLocationOption }) => ({
                url: '/reservations',
                params: { keyword, pageNumber, reservationStatusOption, reservationLocationOption },
            }),
            providesTags: ['Reservation'],
        }),
        
        getPendingReservations: builder.query({
            query: () => ({
                url: '/reservations/pending',
            }),
            providesTags: ['Reservation'],
        }),

        getPresentReservations: builder.query({
            query: () => ({
                url: '/reservations/present',
            }),
            providesTags: ['Reservation'],
        }),

        getPossibleReservations: builder.query({
            query: () => ({
                url: '/reservations/possible',
            }),
            providesTags: ['Reservation'],
        }),

        getArchiveReservations: builder.query({
            query: () => ({
                url: '/reservations/archive',
            }),
            providesTags: ['Reservation'],
        }),

        getMyFutureReservations: builder.query({
            query: () => `/reservations/future`,
            providesTags: ['Reservation'],
            // providesTags: (id) => {
            //     return [{ type: 'Reservation', id: id }];
            // },
        }),

        getMyPastReservations: builder.query({
            query: () => `/reservations/past`,
            providesTags: ['Reservation'],
        }),

        getActiveReservations: builder.query({
            query: () => ({
                url: '/reservations/active',
                // validateStatus: (response, result) => {
                //     return response.status === 200 && !result.isError
                // },
            }),
            // transformResponse: responseData => {
            //     const loadedServices = responseData.map(service => {
            //         service.id = service._id
            //         return service
            //     });
            //     return servicesAdapter.setAll(initialState, loadedServices)
            // },
            // providesTags: (result, error, arg) => {
            //     if (result?.ids) {
            //         return [
            //             { type: 'Service', id: 'LIST' },
            //             ...result.ids.map(id => ({ type: 'Service', id }))
            //         ]
            //     } else return [{ type: 'Service', id: 'LIST' }]
            // }
        }),

        addNewReservation: builder.mutation({
            query: initialReservation => ({
                url: '/reservations',
                method: 'POST',
                body: {
                    ...initialReservation,
                }
            }),
            invalidatesTags: ['Reservation']
            // invalidatesTags: [
            //     { type: 'Reservation', id: "LIST" }
            // ]
        }),

        createReservation: builder.mutation({
            query: initialReservation => ({
                url: '/reservations/create',
                method: 'POST',
                body: {
                    ...initialReservation,
                }
            }),
            invalidatesTags: ['Reservation'],
            // invalidatesTags: [
            //     { type: 'Reservation', id: "LIST" }
            // ]
        }),

        updateReservationToPresent: builder.mutation({
            query: (reservation) => ({
                // url: `/reservations/${reservation.id}/present`,
                url: '/reservations/present',
                method: 'PATCH',
                body: reservation,
            }),
            invalidatesTags: ['Reservation'],
            // invalidatesTags: (result, error, arg) => [
            //     { type: 'Reservation', id: arg.id }
            // ]
        }),

        updateReservationToDone: builder.mutation({
            query: (reservation) => ({
                // url: `/reservations/${reservation.id}/done`,
                url: '/reservations/done',
                method: 'PATCH',
                body: reservation,
            }),
            invalidatesTags: ['Reservation'],
            // invalidatesTags: (result, error, arg) => [
            //     { type: 'Reservation', id: arg.id }
            // ]
        }),

        updateReservationToCancel: builder.mutation({
            query: (reservation) => ({
                url: `/reservations`,
                method: 'PATCH',
                body: reservation,
            }),
            invalidatesTags: ['Reservation'],
            // invalidatesTags: (result, error, arg) => [
            //     { type: 'Reservation', id: arg.id }
            // ]
        }),

        updateColorNote: builder.mutation({
            query: (reservation) => ({
                url: `/reservations/past`,
                method: 'PATCH',
                body: reservation,
            }),
            invalidatesTags: ['Reservation'],
            // invalidatesTags: (result, error, arg) => [
            //     { type: 'Reservation', id: arg.id }
            // ]
        }),

        deleteReservation: builder.mutation({
            query: ({ id }) => ({
                url: `/reservations`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: ['Reservation'],
            // invalidatesTags: (result, error, arg) => [
            //     { type: 'Reservation', id: arg.id }
            // ]
        }),
    }),
})

export const {
    useGetReservationsQuery,
    useGetPendingReservationsQuery,
    useGetPresentReservationsQuery,
    useGetPossibleReservationsQuery,
    useGetArchiveReservationsQuery,
    useGetActiveReservationsQuery,
    useGetMyFutureReservationsQuery,
    useGetMyPastReservationsQuery,
    useAddNewReservationMutation,
    useCreateReservationMutation,
    useUpdateReservationToPresentMutation,
    useUpdateReservationToDoneMutation,
    useUpdateReservationToCancelMutation,
    useUpdateColorNoteMutation,
    useDeleteReservationMutation,
} = reservationsApiSlice

// returns the query result object
export const selectReservationsResult = reservationsApiSlice.endpoints.getReservations.select()

// creates memoized selector
const selectReservationsData = createSelector(
    selectReservationsResult,
    reservationsResult => reservationsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllReservations,
    selectById: selectReservationById,
    selectIds: selectReservationIds
    // Pass in a selector that returns the notes slice of state
} = reservationsAdapter.getSelectors(state => selectReservationsData(state) ?? initialState)