import { Link, useParams } from "react-router-dom";
import { useGetUsersQuery } from "./usersApiSlice";
import User from './User';
import useTitle from "../../hooks/useTitle";
import PulseLoader from 'react-spinners/PulseLoader';
import PaginationUserList from "../../components/paginations/PaginationUserList";
import SearchUserList from "../../components/searchbox/SearchUserList";

function UsersList() {

    useTitle('Ongles-Jessica: Users List');

    // const { pageNumber, keyword } = useParams();
    const { pageNumber = 1, keyword = "" } = useParams();

    const {
        data: usersData,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUsersQuery({
        keyword,
        pageNumber,
        // pollingInterval: 15 * 60 * 1000,
        // refetchOnFocus: true,
        // refetchOnMountOrArgChange: true
    });
    console.log(usersData)

    let content

    if (isLoading) content = <PulseLoader color={"#FFF"} />

    if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    }

    if (isSuccess) {

        const { ids, countUsers, pages, page} = usersData;

        const tableContent = ids?.length > 0 && ids?.map(userId => (
            <User 
                key={userId} 
                userId={userId} 
                pageNumber={pageNumber} 
                keyword={keyword} 
            />
        ))

        content = (
            <main className="container py-3">
                <section className="pb-3">
                    { keyword && 
                        <Link 
                            to='/dashboard/users'
                            className="btn btn-dark"
                        >
                            Go Back
                        </Link>
                    }
                </section>
                <section>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                        <h2>List of <span className="fw-bold">{countUsers}</span> Users</h2>
                        <div className="d-flex align-items-center w-25">
                            <SearchUserList />
                        </div>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div>
                                <Link to='/dashboard/users/new' className="btn btn-outline-secondary">
                                    Add User
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <hr />
                <section>
                    <table className="table striped bordered hover responsive">
                        <thead className="table__thead">
                            <tr>
                                <th scope="col" className="table__th user__username">Name</th>
                                <th scope="col" className="table__th user__email">Email</th>
                                <th scope="col" className="table__th user__roles">Roles</th>
                                <th scope="col" className="table__th user__status">Status</th>
                                <th scope="col" className="table__th user__edit">Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableContent}
                        </tbody>
                    </table>
                </section>
                <section className="pt-3">
                    <PaginationUserList pages={pages} page={page} keyword={keyword} />
                </section>
            </main>
        )
    }

    return content
}
export default UsersList