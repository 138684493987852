import { Outlet, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRefreshMutation } from "./authApiSlice";
import usePersist from "../../hooks/usePersist";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "./authSlice";

const PersistLogin = () => {
    const [persist] = usePersist();
    const token = useSelector(selectCurrentToken);
    const [trueSuccess, setTrueSuccess] = useState(false);

    const [refresh, { isLoading, isSuccess, isError, error }] = useRefreshMutation();

    useEffect(() => {
        const verifyRefreshToken = async () => {
            try {
                await refresh().unwrap();
                setTrueSuccess(true);
            } catch (err) {
                console.error("Error verifying refresh token:", err);
            }
        };

        if (!token && persist) verifyRefreshToken();
        // eslint-disable-next-line
    }, [token, persist, refresh]);

    if (!persist) {
        // Persist is disabled
        return <Outlet />;
    }

    if (isLoading) {
        // Loading state
        return <p>Loading...</p>;
    }

    if (isError) {
        // Error state
        console.error("Refresh error:", error);
        return <Navigate to="/login" replace />;
    }

    if (token || (isSuccess && trueSuccess)) {
        // Token exists or refresh was successful
        return <Outlet />;
    }

    return null; // Fallback if no other condition matches
};

export default PersistLogin;