import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
    useUpdateReservationToCancelMutation,
    useDeleteReservationMutation,
} from "./reservationsApiSlice";

function ReservationListPossibleItem({ reservation }) {
    const [
        updateReservationToCancel,
        { isLoading: isUpdating, isSuccess, isError: isUpdateError, error: updateError },
    ] = useUpdateReservationToCancelMutation();

    const [
        deleteReservation,
        { isLoading: isDeleting, isSuccess: isDeleteSuccess, isError: isDeleteError, error: deleteError },
    ] = useDeleteReservationMutation();

    const navigate = useNavigate();

    useEffect(() => {
        if (isSuccess || isDeleteSuccess) {
            navigate("/dashboard/reservations/possible");
        }
    }, [isSuccess, isDeleteSuccess, navigate]);

    const handleUpdateReservationToCancel = async () => {
        try {
            await updateReservationToCancel({ id: reservation?._id });
        } catch (err) {
            console.error("Failed to update reservation:", err);
        }
    };

    const handleDeleteReservation = async () => {
        if (window.confirm("Are you sure?")) {
            try {
                await deleteReservation({ id: reservation?._id });
            } catch (err) {
                console.error("Failed to delete reservation:", err);
            }
        }
    };

    return (
        <>
        <tr className={`${reservation?.reservationLocation?.locationReserved === "1" ? "table-light" : ""}`}>
            <td>{reservation?.reservationDate?.dateReserved || "- - -"}</td>
            <td>{reservation?.reservationDate?.timeReserved || "- - -"}</td>
            <td>{reservation?.reservationClientInfo?.client || "- - -"}</td>
            <td>
                {reservation?.reservationServices?.length > 0 ? (
                    <ul className="list-unstyled">
                    {reservation.reservationServices.map((service, index) => (
                        <li key={index}>
                        {`${service?.titre} (${service?.qty} place${service?.qty > 1 ? 's' : ''})`}
                        {index < reservation.reservationServices.length - 1 && ','}
                        </li>
                    ))}
                    </ul>
                ) : (
                    <div>{reservation?.reservationService?.titre || "- - -"}</div>
                )}
            </td>
            <td>{reservation?.performer || "- - -"}</td>
            <td>{reservation?.status || "- - -"}</td>
            <td>
            <button
                className="btn btn-info btn-sm"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target={`#offcanvasRight${reservation?._id}`}
                aria-controls={`offcanvasRight${reservation?._id}`}
            >
                View
            </button>
            <div
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                id={`offcanvasRight${reservation?._id}`}
                aria-labelledby="offcanvasRightLabel"
            >
                <div className="offcanvas-header bg-info">
                    <h5 className="offcanvas-title" id="offcanvasRightLabel">
                        Reservation Details
                    </h5>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    ></button>
                </div>
                <div className="offcanvas-body">
                <table className="table table-borderless">
                    <tbody>
                    <tr>
                        <td className="col-sm-2 text-end text-muted">Service</td>
                        <td>
                            {reservation?.reservationServices?.length > 0 ? (
                                <ul className="list-unstyled">
                                {reservation.reservationServices.map((service, index) => (
                                    <li key={index}>
                                    {`${service?.titre} (${service?.qty} place${service?.qty > 1 ? 's' : ''})`}
                                    {index < reservation.reservationServices.length - 1 && ','}
                                    </li>
                                ))}
                                </ul>
                            ) : (
                                reservation?.reservationService?.titre || "- - -"
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="col-sm-2 text-end text-muted">avec</td>
                        <td>{reservation?.performer || "- - -"}</td>
                    </tr>
                    <tr>
                        <td className="col-sm-2 text-end text-muted">Quand</td>
                        <td>
                            {reservation?.reservationDate?.dateReserved || "- - -"} à{" "}
                            {reservation?.reservationDate?.timeReserved || "- - -"}
                        </td>
                    </tr>
                    <tr>
                        <td className="col-sm-2 text-end text-muted">Client</td>
                        <td>{reservation?.reservationClientInfo?.client || "- - -"}</td>
                    </tr>
                    <tr>
                        <td className="col-sm-2 text-end text-muted">Phone</td>
                        <td>{reservation?.reservationClientInfo?.phone || "- - -"}</td>
                    </tr>
                    <tr>
                        <td className="col-sm-2 text-end text-muted">Lieu</td>
                        <td>
                        {reservation?.reservationLocation?.locationReserved === "1"
                            ? "La Pyramide (2360 chemin St-Foy, Québec, G1V 4H2)"
                            : "Plaza Laval (2750 chemin St-Foy, Québec, G1V 1V6)"}
                        </td>
                    </tr>
                    <tr>
                        <td className="col-sm-2 text-end text-muted">Comment</td>
                        <td>{reservation?.reservationClientInfo?.comment || "- - -"}</td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
            </td>
            <td>
            <button
                className="btn btn-secondary btn-sm"
                onClick={handleUpdateReservationToCancel}
                disabled={isUpdating}
            >
                {isUpdating ? "Updating..." : "Cancel"}
            </button>
            </td>
            <td>
            <button
                className="btn btn-danger btn-sm"
                onClick={handleDeleteReservation}
                disabled={isDeleting}
            >
                {isDeleting ? "Deleting..." : <i className="bi bi-trash"></i>}
            </button>
            </td>
        </tr>

        {/* Error Messages */}
        {(isUpdateError || isDeleteError) && (
            <tr>
                <td colSpan="9" className="text-danger">
                    {isUpdateError && (
                        <div>Failed to update reservation: {updateError?.data?.message || updateError?.message || "Unknown error"}</div>
                    )}
                    {isDeleteError && (
                        <div>Failed to delete reservation: {deleteError?.data?.message || deleteError?.message || "Unknown error"}</div>
                    )}
                </td>
            </tr>
        )}
        </>
    );
}

export default ReservationListPossibleItem;