import { useNavigate } from 'react-router-dom';
import { useGetUsersQuery } from './usersApiSlice';
import { memo } from 'react';

function User({ userId, pageNumber, keyword }) {

    const { user } = useGetUsersQuery(
        { keyword, pageNumber }, 
        {
            selectFromResult: ({ data }) => ({
                user: data?.entities?.[userId], // Select individual user
            }),
        }
    );

    const navigate = useNavigate();

    if (user) {
        const handleEdit = () => navigate(`/dashboard/users/${userId}`);
        // const handleEdit = () => {
        //     if (user) {
        //         navigate(`/dashboard/users/${userId}`);
        //     } else {
        //         console.error("User not found for the current page.");
        //     }
        // };

        const userRolesString = user.roles.toString().replaceAll(',', ', ')

        const cellStatus = user.status ? '' : 'table__cell--inactive'

        return (
            <tr className="table__row user">
                <td className={`table__cell ${cellStatus}`}>{user.name}</td>
                <td className={`table__cell ${cellStatus}`}>{user.email}</td>
                <td className={`table__cell ${cellStatus}`}>{userRolesString}</td>
                <td className={`table__cell ${cellStatus}`}>{user.status ? 'Active' : 'Closed'}</td>
                <td className={`table__cell ${cellStatus}`}>
                    <button
                        className="btn btn-secondary btn-sm"
                        onClick={handleEdit}
                    >
                        Edit | Delete
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedUser = memo(User)

export default memoizedUser