import { store } from '../../app/store';
// import { notesApiSlice } from '../notes/notesApiSlice';
import { usersApiSlice } from '../users/usersApiSlice';
import { helpsApiSlice } from '../helps/helpsApiSlice';
import { servicesApiSlice } from '../services/servicesApiSlice';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const Prefetch = () => {
    // useEffect(() => {
    //     console.log('subscribing')
    //     const notes = store.dispatch(notesApiSlice.endpoints.getNotes.initiate());
    //     const users = store.dispatch(usersApiSlice.endpoints.getUsers.initiate());

    //     return () => {
    //         console.log('unsubscribing')
    //         notes.unsubscribe();
    //         users.unsubscribe();
    //     }
    // }, [])

    useEffect(() => {
        // store.dispatch(notesApiSlice.util.prefetch('getNotes', 'notesList', { force: true }))
        store.dispatch(usersApiSlice.util.prefetch('getUsers', 'usersList', { force: true }))
        store.dispatch(helpsApiSlice.util.prefetch('getHelps', 'helpsList', { force: true }))
        store.dispatch(helpsApiSlice.util.prefetch('getPublicHelps', 'publicHelps', { force: true }))
        store.dispatch(servicesApiSlice.util.prefetch('getServices', 'servicesList', { force: true }))
        store.dispatch(servicesApiSlice.util.prefetch('getPublicServices', 'publicServices', { force: true }))
    }, [])

    return <Outlet />
}
export default Prefetch