import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function SearchReservationList({ keyword }) {
    const navigate = useNavigate();
    const [searchKeyword, setSearchKeyword] = useState(keyword || "");

    // This will update searchKeyword if keyword prop changes
    useEffect(() => {
        setSearchKeyword(keyword);
    }, [keyword]);

    const handleSearchForm = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        if (searchKeyword.trim()) {
            navigate(`/dashboard/reservations/search/${searchKeyword.trim()}`); // Navigate with the search keyword
        } else {
            navigate("/dashboard/reservations"); // Navigate to default reservations page if the search is empty
        }
    };

    return (
        <form className="d-flex" role="search" onSubmit={handleSearchForm}>
            <input
                type="text"
                className="form-control rounded-pill"
                value={searchKeyword}
                placeholder="Search..."
                onChange={(e) => setSearchKeyword(e.target.value)} // Update state as the user types
            />
            <button
                type="submit"
                style={{
                    position: "relative",
                    left: "-2rem",
                    border: "none",
                    background: "none",
                }}
            >
                <i className="bi bi-search"></i>
            </button>
        </form>
    );
}

export default SearchReservationList;