import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function SearchUserList() {

    const navigate = useNavigate();
    const { keyword: urlKeyword } = useParams();

    const [keyword, setKeyword] = useState(urlKeyword || '');

    const handleSearchForm = (e) => {
        e.preventDefault();
        if (keyword.trim()) {
            setKeyword('');
            navigate(`/dashboard/users/search/${keyword}`);
        } else {
            navigate('/dashboard/users');
        }
    }

    return (
            <form 
                className="d-flex" 
                role="search"
                onSubmit={handleSearchForm}
            >
                {/* <input 
                    className="form-control me-2" 
                    type="search"
                    name='q'
                    value={keyword}
                    placeholder="Search . . ." 
                    aria-label="Search"
                    onChange={(e) => setKeyword(e.target.value)}
                />
                <button 
                    className="btn btn-outline-success" 
                    type="submit"
                >
                    <i className="fa-solid fa-magnifying-glass"></i>
                </button> */}
               
                    <input
                        type="text"
                        className="form-control rounded-pill"
                        name='q'
                        value={keyword}
                        placeholder="Search..."
                        onChange={(e) => setKeyword(e.target.value)}
                    />
                    <button type="submit" style={{ position: "relative", left: "-2rem", border: "none", background: "none" }}>
                        <i className="bi bi-search"></i>
                    </button>
            </form>
    )
}

export default SearchUserList